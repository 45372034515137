import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"

class CallToAction extends React.Component {
  constructor(props) {
    super(props);
    const node = this.props.node
    const ctaElement = contentfulMapToLocalized(node.fields)    
    this.url = ctaElement.url
    this.label = ctaElement.label
    this.extraAttributes = get(ctaElement, 'extraAttributes', {})
    
    const alignment = get(ctaElement, 'alignment', 'left').toLowerCase()
    this.wrapperClasses = ['call-to-action']
    switch (alignment) {
      case 'left': this.wrapperClasses.push('text-left'); break
      case 'center': this.wrapperClasses.push('text-center'); break
      case 'right': this.wrapperClasses.push('text-right'); break
    }
    this.wrapperClasses = this.wrapperClasses.join(' ')
    
    const color = get(ctaElement, 'color', 'blue').toLowerCase()    
    this.elementClasses = ['btn']
    switch (color) {
      case 'blue': this.elementClasses.push('btn-primary'); break
      case 'yellow': this.elementClasses.push('btn-secondary'); break
      case 'dark-gray': this.elementClasses.push('btn-dark'); break
      case 'light-gray': this.elementClasses.push('btn-light'); break
      case 'white': this.elementClasses.push('btn-white'); break
      case 'green': this.elementClasses.push('btn-success'); break
    }
    this.elementClasses = this.elementClasses.join(' ')
    
    if (this.extraAttributes.className) {
      this.elementClasses += ` ${this.extraAttributes.className}`
      delete this.extraAttributes.className
    }
  }
  render() {    
    let renderedElement
    // if (isExternalUrlOrHash(this.url)) {
    //   renderedElement = (<a href={this.url} target="_blank" rel="noreferrer" className={this.elementClasses} { ...this.extraAttributes }>{this.label}</a>)
    // } else {
    //   renderedElement = (<Link to={this.url} { ...this.extraAttributes } className={this.elementClasses}>{this.label}</Link>)
    // }
    if (isExternalUrlOrHash(this.url)) {
      renderedElement = (<a href={this.url} target="_top" rel="noreferrer" className={this.elementClasses} { ...this.extraAttributes }>{this.label}</a>)
    } else {
      renderedElement = (<a href={`https://www.honestpaws.com${this.url}`} target="_top" rel="noreferrer" className={this.elementClasses} { ...this.extraAttributes }>{this.label}</a>)
    }
    
    return (
      <p className={this.wrapperClasses}> 
        { renderedElement } 
      </p> 
    )
  }
}

export default CallToAction
